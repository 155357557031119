import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { ArticleListHeading } from '../../components/organisms/ArticleListHeading';
import { SectionHeading } from '../../components/atoms/SectionHeading';
import {
  DocumentsCard,
  DocumentsCardNodeType,
} from '../../components/documents/DocumentsCard';

export const Head = () => (
  <HeadInner
    path='/seminar'
    title='セミナー'
    description='paild（ペイルド）のセミナーです。全て無料でご参加いただけます。また、すぐにご視聴できるアーカイブ動画もご用意しています'
  />
);

const SeminarPage: React.FC<{ data: GatsbyTypes.SeminarQueryQuery }> = (
  props
) => {
  const allSeminarList: DocumentsCardNodeType[] =
    props.data.allContentfulSeminar.nodes
      .map((node) => {
        return {
          slug: node.slug!,
          title: node.title!,
          image: node.image?.gatsbyImageData!,
        };
      })
      .filter((node) => node.slug !== 'dummy' || null);
  const allVideosList: DocumentsCardNodeType[] =
    props.data.allContentfulVideo.nodes
      .map((node) => {
        return {
          slug: node.slug!,
          title: node.title!,
          image: node.coverImage?.gatsbyImageData!,
          videoId: node.videoId || '',
          priority: node.priority,
        };
      })
      .filter((node) => node.slug !== null)
      .sort((a, b) => (a.priority! > b.priority! ? -1 : 1));

  const links = [{ name: 'セミナー', path: '/seminar/' }];

  return (
    <Layout links={links}>
      <ArticleListHeading
        category='seminar'
        title='セミナー'
        description={[
          'セミナーは全て無料でご参加いただけます。',
          'また、すぐにご視聴できるアーカイブ動画もご用意しております。',
        ]}
      />
      <section css={DocumentsSectionStyle}>
        <SectionHeading>セミナー</SectionHeading>
        <div css={SeminarListStyle(allSeminarList.length)}>
          {allSeminarList.length > 0 ? (
            allSeminarList.map((node, index) => {
              return <DocumentsCard key={index} node={node} type='seminar' />;
            })
          ) : (
            <p>現在開催予定のセミナーはありません。</p>
          )}
        </div>
      </section>
      <section css={DocumentsSectionStyle}>
        <SectionHeading>セミナーアーカイブ動画</SectionHeading>
        <div css={DocumentsListStyle}>
          {allVideosList.map((node, index) => {
            return <DocumentsCard key={index} node={node} type='videos' />;
          })}
        </div>
      </section>
    </Layout>
  );
};

const DocumentsSectionStyle = css({
  width: '100%',
  maxWidth: 1120,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 40,
  gap: 60,

  '@media(max-width: 1119px)': {
    maxWidth: 710,
    gap: 30,
  },

  '@media(max-width: 768px)': {
    padding: '0 17px',
  },
});

const SeminarListStyle = (length: number) =>
  css({
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: length > 2 ? '1fr 1fr 1fr' : '1fr 1fr',
    width: '100%',
    gap: 50,

    '@media(max-width: 1119px)': {
      gridTemplateColumns: '1fr 1fr',
      maxWidth: 734,
      gap: 40,
    },

    '@media(max-width: 768px)': {
      gridTemplateColumns: '1fr',
      gap: 20,
    },
  });

const DocumentsListStyle = css({
  display: 'grid',
  justifyItems: 'center',
  gridTemplateColumns: '1fr 1fr 1fr',
  width: '100%',
  gap: 50,

  '@media(max-width: 1119px)': {
    maxWidth: 734,
    gridTemplateColumns: '1fr 1fr',
    gap: 40,
  },

  '@media(max-width: 768px)': {
    gridTemplateColumns: '1fr',
    gap: 20,
  },
});

export const query = graphql`
  query SeminarQuery {
    allContentfulSeminar(
      sort: { fields: createdAt, order: DESC }
      filter: { isClosed: { ne: true } }
    ) {
      nodes {
        slug
        title
        image {
          gatsbyImageData
        }
      }
    }
    allContentfulVideo {
      nodes {
        title
        slug
        coverImage {
          gatsbyImageData
        }
        videoId
        priority
      }
    }
  }
`;

export default SeminarPage;
